<template>
    <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
                v-model="user.cf1cep"
                :rules="cepRules"
                label="CEP"
                required
                dense
                flat
                outlined
                placeholder="Ex.: 95180-000"
                :color="parameters.primaryColor"
                v-mask="'#####-###'"
                @blur="onFocusOut"
                append-icon="mdi-magnify"
                @click:append="onFocusOut"
            ></v-text-field>

            <v-text-field
                v-model="user.cf1ender1"
                :rules="addressRules"
                label="Endereço"
                type="text"
                required
                dense
                flat
                outlined
                placeholder="Ex.: Rua Pedro Álvares Cabral"
                :color="parameters.primaryColor"
            ></v-text-field>

            <p
                v-if="user.cf3nome && user.cf3estado"
                :style="{ color: parameters.primaryColor }"
                class="body-1 text-left"
            >
                <v-icon dark :color="parameters.primaryColor" class="pr-2"
                    >mdi-domain</v-icon
                >
                {{ user.cf3nome }} - {{ user.cf3estado }}
            </p>

            <v-text-field
                v-model="user.cf1numero"
                :rules="numberRules"
                label="Número"
                type="text"
                required
                dense
                flat
                placeholder="Ex.: 123 ou S/N"
                outlined
                :color="parameters.primaryColor"
            ></v-text-field>

            <v-text-field
                v-model="user.cf1bairro"
                :rules="districtRules"
                label="Bairro"
                type="text"
                dense
                flat
                outlined
                placeholder="Ex.: Centro"
                :color="parameters.primaryColor"
            ></v-text-field>

            <v-text-field
                v-model="user.cf1compl"
                label="Complemento"
                type="text"
                required
                dense
                placeholder="Ex.: Casa/ Apto / próximo à"
                flat
                outlined
                :color="parameters.primaryColor"
            ></v-text-field>
        </v-form>
    </v-card-text>
</template>

<script>
export default {
    data: () => ({
        valid: true,
        cepRules: [
            (v) => !!v || "CEP é obrigatório",
            (v) => (v && v.replace(/[^\d]/g, "").length == 8) || "CEP inválido",
        ],
        addressRules: [(v) => !!v || "Endereço é obrigatório"],
        numberRules: [(v) => !!v || "Número é obrigatório"],
        districtRules: [(v) => !!v || "Bairro é obrigatório"],
    }),
    props:{
        user:{
            type: Object,
            required: true
        }
    },
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
    },
    methods: {
        validateForm() {
            return this.$refs.form.validate();
        },
        onFocusOut() {
            this.submitZipCode(this.user.cf1cep);
        },
        submitZipCode(zipCode) {
            var str = new String(zipCode);
            str = str.trim();
            str = str.replace(/[^\d]/g, "");

            if (str.length != 8) {
                return;
            }
            this.$store.dispatch("signUp/getCep", str).then((addressModel) => {
                if (addressModel.erro) {
                    this.user.cf1ender1 = "";
                    this.user.cf1bairro = "";
                    this.user.cf3nome = "";
                    this.user.cf3estado = "";
                    return;
                }
                this.user.cf1cep = addressModel.cep;
                this.user.cf1ender1 = addressModel.logradouro;
                this.user.cf1bairro = addressModel.bairro;
                this.user.cf3nome = addressModel.localidade;
                this.user.cf3estado = addressModel.uf;
            });
        },
    },
};
</script>

<style>
</style>