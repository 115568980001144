<template>
    <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
                v-model="user.cf1nome"
                :rules="nameRules"
                label="Razão social"
                type="text"
                required
                dense
                flat
                placeholder="Ex.: Maria Pereira Comercio de roupas"
                outlined
                :color="parameters.primaryColor"
            ></v-text-field>

            <v-text-field
                v-model="user.cf1fant"
                :rules="nameFantRules"
                label="Nome fantasia"
                type="text"
                required
                dense
                flat
                placeholder="Ex.: Loja da Maria"
                outlined
                :color="parameters.primaryColor"
            ></v-text-field>

            <v-text-field
                v-model="user.cf1insest"
                label="Inscrição estadual"
                type="number"
                dense
                placeholder="Ex.: 123/4567891"
                flat
                outlined
                :color="parameters.primaryColor"
            ></v-text-field>

            <v-text-field
                v-model="user.cf1confone"
                :rules="cellPhoneRules"
                label="Celular"
                type="text"
                required
                dense
                flat
                placeholder="Ex.: (99) 99999-9999"
                outlined
                v-mask="maskConfone"
                :color="parameters.primaryColor"
            ></v-text-field>

            <v-text-field
                v-model="user.cf1fone"
                :rules="phoneRules"
                label="Telefone"
                type="text"
                required
                dense
                placeholder="Ex.: (99) 3222-2222"
                flat
                outlined
                v-mask="maskFone"
                :color="parameters.primaryColor"
            ></v-text-field>

            <v-text-field
                v-model="user.cf1email"
                :rules="emailRules"
                label="E-mail"
                type="text"
                placeholder="Ex.: joao@email.com"
                required
                dense
                flat
                outlined
                :color="parameters.primaryColor"
            ></v-text-field>
        </v-form>
    </v-card-text>
</template>

<script>
import { VueMaskDirective } from 'v-mask'

export default {
    data: () => ({
        valid: true,
        nameRules: [
            (v) => !!v || "Razão social é obrigatória",
            (v) => v && v.length > 3 || "Razão social inválida",
            /* (v) => (v && /^(?:[\u00c0-\u01ffa-zA-Z'-]){2,}(?:\s[\u00c0-\u01ffa-zA-Z'-]{2,})+$/i.test(v)) || "Razão social inválida", */
        ],
        nameFantRules: [
            (v) => !!v || "Nome fantasia é obrigatório",
            (v) => v && v.length > 3 || "Nome fantasia inválido",
            /* (v) => (v && /^(?:[\u00c0-\u01ffa-zA-Z'-]){2,}(?:\s[\u00c0-\u01ffa-zA-Z'-]{2,})+$/i.test(v)) || "Nome fantásia inválido", */
        ],
        cellPhoneRules: [
            (v) => !!v || "Celular é obrigatório",
            /* (v) => (v && /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/.test(v.replace(/[^\d]/g, ""))) || "Celular inválido", */
        ],
        phoneRules: [
            /* (v) => !!v || "Telefone é obrigatório", */
            /* (v) => (v == "" || /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/.test(v.replace(/[^\d]/g, ""))) || "Telefone inválido", */
        ],
        emailRules: [
            (v) => !!v || "E-mail é obrigatório",
            (v) => (v && /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/.test(v)) || "E-mail inválido",
        ],
    }),
    props:{
        user:{
            type: Object,
            required: true
        }
    },
    directives:{ VueMaskDirective },
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        login: {
            get() {
                return this.$store.state.login;
            },
        },
        maskFone(){
            
            return this.user.cf1fone && this.user.cf1fone.replace(/[^\d]/g, "").length > 10 ? "(##) #####-####" : "(##) ####-####"
        },
        maskConfone(){
            return this.user.cf1confone && this.user.cf1confone.replace(/[^\d]/g, "").length > 10 ? "(##) #####-####" : "(##) ####-####"
        }
    },
    methods: {
        validateForm(){
            return this.$refs.form.validate()
        }
    },
};
</script>

<style>
</style>