<template>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="login.showChangePass"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn 
                dark
                :color="parameters.secondaryColor"
                @click="login.showChangePass = true"
                v-on="on"
                v-bind="attrs"
            >Alterar a senha</v-btn>
        </template>

        <v-card>
            <v-toolbar dark :color="parameters.secondaryColor" class="title">Altere sua senha</v-toolbar>
            <v-card-subtitle class="pt-5">Digite e confirme a nova senha abaixo.</v-card-subtitle>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field
                        v-model="cf1senha"
                        :rules="passwordRules"
                        label="Senha atual"
                        type="password"
                        required
                        dense
                        flat
                        outlined
                        :color="parameters.primaryColor"
                    ></v-text-field>
                    <v-text-field
                        v-model="newPass"
                        :rules="passwordRules"
                        label="Nova senha"
                        type="password"
                        required
                        dense
                        flat
                        outlined
                        :color="parameters.primaryColor"
                    ></v-text-field>

                    <v-text-field
                        v-model="confirmPassword"
                        :rules="passwordRules"
                        label="Confirme a senha"
                        type="password"
                        required
                        dense
                        flat
                        outlined
                        :color="parameters.primaryColor"
                    ></v-text-field>
                    <p class="subtitle-2 font-weight-bold text-left ma-0">Para alterar sua senha, lembre-se que:</p>
                    <ul>
                        <li class="caption text-left">deve possuir no mínimo 8 dígitos</li>
                        <li class="caption text-left">deve possuir no mínimo 1 número</li>
                        <li class="caption text-left">deve possuir no mínimo 1 letra maiúscula</li>
                        <li class="caption text-left">deve possuir no mínimo 1 letra minúscula</li>
                    </ul>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn text @click="close">Fechar</v-btn>
                <v-btn dark :color="parameters.secondaryColor" @click="send">Alterar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        cf1senha: "",
        newPass: "",
        confirmPassword: "",
        valid: true,
        passwordRules: [
            (v) => !!v || "A senha é obrigatória",
            (v) => (v && v.length >= 8) || "No mínimo 8 dígitos",
            (v) => (v && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(v)) || "Senha não cumpre os requisitos de segurança", //(?=.*[!@#$%^&*])
        ],
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        login: {
            get() {
                return this.$store.state.login;
            },
        },
    },
    methods:{
        send(){
            if (this.$refs.form.validate()) {
                this.$store.dispatch("login/updateCredentials", 
                { 
                    "cf1senha": this.cf1senha,
                    "newPass": this.newPass,
                    "confirmPassword": this.confirmPassword 
                }).then(() => {
                    this.cf1senha = ""
                    this.newPass = ""
                    this.confirmPassword = ""
                })
            }
        },
        close(){
            this.cf1senha = ""
            this.newPass = ""
            this.confirmPassword = ""
            this.login.showChangePass = false
        }
    }
};
</script>

<style>
</style>