<template>
    <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
                v-model="user.shippingAddress.cfbcepent"
                :rules="cepRules"
                label="CEP"
                required
                dense
                placeholder="Ex.: 95180-000"
                flat
                outlined
                :color="parameters.primaryColor"
                v-mask="'#####-###'"
                @blur="onFocusOut"
                append-icon="mdi-magnify"
                @click:append="onFocusOut"
            ></v-text-field>

            <v-text-field
                v-model="user.shippingAddress.cfbendent"
                :rules="addressRules"
                label="Endereço"
                type="text"
                required
                dense
                flat
                placeholder="Ex.: Rua Pedro Álvares Cabral, 333"
                outlined
                :color="parameters.primaryColor"
            ></v-text-field>

            <p
                v-if="user.shippingAddress.cf3nome && user.shippingAddress.cf3estado"
                :style="{ color: parameters.primaryColor }"
                class="body-1 text-left"
            >
                <v-icon dark :color="parameters.primaryColor" class="pr-2"
                    >mdi-domain</v-icon
                >
                {{ user.shippingAddress.cf3nome }} - {{ user.shippingAddress.cf3estado }}
            </p>

            <v-text-field
                v-model="user.shippingAddress.cfbproximo"
                label="Complemento"
                type="text"
                required
                dense
                placeholder="Ex.: Casa/ Apto / próximo à"
                flat
                outlined
                :color="parameters.primaryColor"
            ></v-text-field>
        </v-form>
    </v-card-text>
</template>

<script>
export default {
    data: () => ({
        valid: true,
        cepRules: [
            (v) => !!v || "CEP é obrigatório",
            (v) => (v && v.replace(/[^\d]/g, "").length == 8) || "CEP inválido",
        ],
        addressRules: [(v) => !!v || "Endereço é obrigatório"],
    }),
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
    },
    methods: {
        validateForm() {
            return this.$refs.form.validate();
        },
        onFocusOut() {
            this.submitZipCode(this.user.shippingAddress.cfbcepent);
        },
        submitZipCode(zipCode) {
            var str = new String(zipCode);
            str = str.trim();
            str = str.replace(/[^\d]/g, "");

            if (str.length != 8) {
                return;
            }
            this.$store.dispatch("signUp/getCep", str)
            .then((addressModel) => {
                if (addressModel.erro) {
                    this.user.shippingAddress.cfbcepent = "";
                    this.user.shippingAddress.cfbendent = "";
                    this.user.shippingAddress.cf3nome = "";
                    this.user.shippingAddress.cf3estado = "";
                    return;
                }
                this.user.shippingAddress.cfbcepent = addressModel.cep;
                this.user.shippingAddress.cfbendent = `${addressModel.logradouro} - ${addressModel.bairro}`;
                this.user.shippingAddress.cf3nome = addressModel.localidade;
                this.user.shippingAddress.cf3estado = addressModel.uf;
            });
        },
    },
};
</script>

<style>
</style>