<template>
    <v-col cols="12">
        <v-avatar color="teal" size="128">
            <v-img :src="cf1foto1" alt="Avatar" v-on:error="onImgError"/>
        </v-avatar>
        <p class="font-weight-bold text-h6 pt-2" :style="{color: parameters.secondaryColor}">{{ user.cf1nome }}</p>
    </v-col>
</template>

<script>
export default {
    data(){
        return{
            failed_image: false
        }
    },
    props:{
        user:{
            type: Object,
            required: true
        }
    },
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        cf1foto1:{
            get(){
                return this.failed_image ? "../../../public/img/accountDefault.png" : this.user.cf1foto1
            }
        }
    },
    methods:{
        onImgError(){
            this.failed_image = true
        }
    }
};
</script>

<style>
</style>